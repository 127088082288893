import { showReportDialog } from '@sentry/vue'

export default function() {
  return {
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    release: process.env.SENTRY_RELEASE,
    environment: process.env.NODE_ENV,

    beforeSend: function(event) {
      // Extract error message
      const errorMessage = event.exception?.values?.[0]?.value || '';

      // Ignore specific timeout-related rejections
      if (errorMessage.includes("Timeout") || errorMessage.includes("Non-Error promise rejection")) {
        return null; // Prevent Sentry from capturing known timeout issues
      }

      // Sentry Bug Reporter UI
      if (event.exception) {
        const user = window?.$nuxt?.$auth?.user;
        showReportDialog({
          eventId: event.event_id,
          title: 'Es gibt scheinbar Probleme auf unserer Seite.',
          subtitle: 'Unser Team wurde benachrichtigt. Wenn du uns helfen möchtest, teile uns bitte unten mit, was geschehen ist.',
          subtitle2: 'Was ist genau passiert?',
          labelComments: 'Beschreibe, welche Aktion du ausführen wolltest oder auf welchen Button du geklickt hast:',
          user: {
            email: user?.email || '',
            name: user ? `${user.firstname} ${user.lastname}` : ''
          }
        });
      }

      return event;
    },
  };
}
